import React, { Component } from "react";

class Contact extends Component {
  render() {
    if (!this.props.data) return null;

    const name = this.props.data.name;
    const street = this.props.data.address.street;
    const phone = this.props.data.phone;
    const email = this.props.data.email;
    const message = this.props.data.contactmessage;
    const googlemap = this.props.data.googlemap;
    const whatsapp = this.props.data.whatsapp;
    const schedule = this.props.data.schedule;

    return (
      <section id="contact">
        <div className="row section-head">
          <div className="two columns header-col">
            <h1>
              <span>Get In Touch.</span>
            </h1>
          </div>

          <div className="ten columns">
            <p className="lead">{message}</p>
          </div>
        </div>

        <div className="row">
          {/* <div className="eight columns">
            <form action="" method="post" id="contactForm" name="contactForm">
              <fieldset>
                <div>
                  <label htmlFor="contactName">
                    Name <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    defaultValue=""
                    size="35"
                    id="contactName"
                    name="contactName"
                    onChange={this.handleChange}
                  />
                </div>

                <div>
                  <label htmlFor="contactEmail">
                    Email <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    defaultValue=""
                    size="35"
                    id="contactEmail"
                    name="contactEmail"
                    onChange={this.handleChange}
                  />
                </div>

                <div>
                  <label htmlFor="contactSubject">Subject</label>
                  <input
                    type="text"
                    defaultValue=""
                    size="35"
                    id="contactSubject"
                    name="contactSubject"
                    onChange={this.handleChange}
                  />
                </div>

                <div>
                  <label htmlFor="contactMessage">
                    Message <span className="required">*</span>
                  </label>
                  <textarea cols="50" rows="15" id="contactMessage" name="contactMessage"></textarea>
                </div>

                <div>
                  <button className="submit">Submit</button>
                  <span id="image-loader">
                    <img alt="" src="images/loader.gif" />
                  </span>
                </div>
              </fieldset>
            </form>

            <div id="message-warning"> Error boy</div>
            <div id="message-success">
              <i className="fa fa-check"></i>Your message was sent, thank you!
              <br />
            </div>
          </div> */}

          <aside className="six columns footer-widgets">
            <div className="widget widget_contact">
              <p className="address">
                {name}
                <br />
                {street} 
                <br />
                Mail: {email} 
                <br />
                <span>Telefon: {phone}</span>
                <br />
                <span>{schedule}</span>
              </p>
              <a href={whatsapp}>
                <img  className="icon-whatsapp" src="images/whatsapp.png" alt="Whatsapp"></img>
              </a>
            </div>
          </aside>
          <aside className="six columns footer-widgets">
            <div className="widget widget_contact">
              <a href={googlemap} >
                <img  className="" src="images/map.png" alt="Map"></img>
              </a>
            </div>
          </aside>
        </div>
      </section>
    );
  }
}

export default Contact;
