import React from "react";

let id = 0;
export default class Service extends React.Component {
  
  onClose = e => {
    this.props.onClose && this.props.onClose(e);
  };

  render() {
    if(!this.props.show){
      return null;
    }

    const services = this.props.project.services.map((service) =>
      <div key={id++} className="four columns">
        <h4>{service.title}</h4>
        <p>{service.time}, {service.price}</p>
      </div>
    );

    return (
      <section>
        <div className="service-detail">
          <div className="row">
            <div className="twuelve column arrow-left">
              <a onClick={e => {this.onClose(e);}} href="#portfolio">
                <i className="fa fa-arrow-left"></i>    {this.props.project.title}
              </a>
            </div>
          </div>
          <br></br>
          <br></br>
          <div className="row offset-1">
            {services}
          </div>
        </div>
      </section>
      
    );
  }
}

