import React, { Component } from "react";
import Zmage from "react-zmage";
import Service from "./Service"


let id = 0;
class Portfolio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      project: null,
      image: null
    };

  }

  showModal = (project)  => {
    this.setState({
      show: !this.state.show,
    });

    if (project !== undefined){
      this.setState({
        project: project,
        image: project.image
      });
    }
  };

  renderButton(){
    return(
      <button onClick={(e) => this.showModal()}> Hello</button>
    )
  }

  render() {
    if (!this.props.data) return null;
    
    const projects = this.props.data.projects.map((project) =>
      <div key={id++} className="columns portfolio-item">
        <div className="item-wrap">
          <div style={{ textAlign: "center" }}>
            <a onClick={(e) => this.showModal(project)}>
            <Zmage alt={project.title} src={project.image} />
              {project.title}
            </a>
          </div>
        </div>
      </div>
    );

    return (
      <section id="portfolio" style={{ backgroundImage:`url(${this.state.image})` }}>
        <div className="row" hidden={this.state.show}>
          <div className="twelve columns collapsed">
            <h1>Überprüfen Sie einige meiner Dienstleistungen.</h1>

            <div id="portfolio-wrapper" className="bgrid-quarters s-bgrid-thirds cf">
              {projects}
            </div>
          </div>
        </div>
        <Service project={this.state.project} onClose={this.showModal} show={this.state.show}>
        </Service>
      </section>
    );
  }
}

export default Portfolio;
