import React, { Component } from "react";

class Resume extends Component {

  render() {
    if (!this.props.data) return null;

    const team = this.props.data.user.map(function(user) {
      var image = "images/users/" + user.image;
  
        return (
          <div key={user.name} className="row">
            <div key={user.name} className="twelve columns">
              <h3>{user.name}</h3>
              <div className="three columns">
                <img className="profile-pic" src={image} alt="Nails and Beauty by Rut Lorenz" />
              </div>
              <div className="nine columns">
                <p className="info">
                  {user.name}
                  <span>&bull;</span> <em className="date">{user.title}</em>
                </p>
                <p className="info">{user.description}</p>
              </div>
             
            </div>
          </div>
        );
    });

    return (
      <section id="resume">
        <div className="row">
          {team}
        </div>
      </section>
    );
  }
}

export default Resume;
