import React, { Component } from "react";


class Offer extends Component {

  render() {
    if (!this.props.data) return null;
    const img_offer = this.props.data.img_offer
    const whatsapp = this.props.data.whatsapp

    return (
        <section id="offer">
            <div className="row">
                <img src={img_offer} alt="Offer"></img>
            </div>
            <div className="row">
                <div className="six columns">
                    <p>Buchen Sie Ihren Termin unter der Telefonnummer</p>
                </div>
                <div className="six columns">
                    <a href={whatsapp}>
                        <img  className="icon-whatsapp" src="images/whatsapp.png" alt="Whatsapp"></img>
                    </a>
                </div>
            </div>
        </section>
    );
  }
}

export default Offer;
